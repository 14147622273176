.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}
.pdf-container {
  display: flex;
  justify-content: center;
  user-select: none;
}

.controls {
  margin: 20px;
}
/* ::-webkit-scrollbar {
  width: 12px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background:  rgb(246,170,70);
  border-radius:1rem;
  margin-block: .2em;

} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background:  linear-gradient(rgb(88,13,19),rgb(246,170,70))  ;
  background:  linear-gradient(black,white)  ;
  background: rgb(246,170,70);
  background: rgb(88,13,19);
  border-radius:1rem;
  border: 2px solid transparent;
  background-clip:content-box;
} */


/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(white,black);
} */












@media print {
  .pdf-container {
    display: none;
  }
}


.video-responsive{
  overflow:hidden;
  padding-bottom: 56.25%;
  position:relative;
  height: 0;
}
.video-responsive iframe{
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position:absolute;
}
