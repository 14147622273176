// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.pdf-container {
	display: flex;
	justify-content: center;
	user-select: none;

}
.pdf-container canvas {

  width: 100% !important;
  height: auto !important;

}

.pdf-container .react-pdf__Page__textContent {
  // width: 100% !important;
  display: none;
}
.controls {
	margin: 20px;
  text-align: center;
}
@media print {
	.pdf-container {
		display: none;
	}
}
@media screen and (max-width: 325px) {
  .controls {
    margin: 10px 0px;
  }
  .controls button {
    margin: 10px 0px 0px 0px;
  }
}

